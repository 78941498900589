import Icon from '@mdi/react';
import { Link } from 'gatsby';
import { mdiClose } from '@mdi/js';
import styled from 'styled-components';
import React, { useContext } from 'react';
import SheetMusic from './icons/SheetMusic';
import { modularScale } from '../util/modularScale';
import { CartContext } from './context/CartContext';
import { Button, IconButton } from '@material-ui/core';
import { background, boxShadow, gradient, grey, greyLight, primary } from '../util/theme';

const Container = styled.div`
	padding: 0 1rem;
`;

const Columns = styled.div`
    margin: 0 auto;
    max-width: 400px;
    
	@media (min-width: 900px) {
	    max-width: 950px;
	    display: flex;
	    align-items: flex-start;
	}
`;

const Column = styled.div`
	@media (min-width: 900px) {
	    padding: 0 1rem;
	}
`;

const BigColumn = styled(Column)`
	flex: 2 1;
`;

const SmallColumn = styled(Column)`
	flex: 1 2;
`;

const Title = styled.h1`
	font-size: ${modularScale(2)};
	font-weight: 400;
	text-align: center;
	margin-bottom: 2rem;
	line-height: 1;
	
	@media (min-width: 900px) {
	    margin-bottom: 3rem;
	}
`;

const ItemList = styled.div`
	margin-bottom: 2rem;
`;

const CartEmptyContainer = styled.div`
	background-color: #fff;
	width: 100%;
	max-width: 600px;
	padding: 1rem;
	border-radius: 4px;
    margin: 0 auto 2rem;
	
	@media (min-width: 900px) {
        padding: 2rem;
    }
`;

const StyledButton = styled(Button)`
	color: #fff;
	
	&:hover, &:focus, &:active {
	    color: #fff;
	}
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const ItemContainer = styled.div`
	display: flex;
	align-items: center;
	background-color: #fff;
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 4px;
	
	&:last-child {
	    margin-bottom: 0;
	}
`;

const Image = styled(SheetMusic)`
	width: 50px;
	height: 50px;
	margin-right: 1rem;
	padding: 5px;
	flex: 0 0 50px;
	
	@media (min-width: 900px) {
	    padding: 2px;
	    width: 30px;
	    height: 30px;
	    flex: 0 0 30px;
	}
`;

const Info = styled.div`
    @media (min-width: 900px) {
        display: flex;
        align-items: center;
        width: 75%;
    }
`;

const ItemTitle = styled.div`
    line-height: 1.3;
    display: -webkit-box;
    max-height: 3.3rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    
    @media (min-width: 900px) {
        margin-bottom: 0;
        -webkit-line-clamp: 1;
    }
`;

const ItemPrice = styled.div`
    font-weight: bold;

    @media (min-width: 900px) {
        margin-left: auto;
        white-space: nowrap;
    }
`;

const Remove = styled(IconButton)`
    margin-left: auto;

	svg {
	    width: 20px;
	}
`;

const OrderActions = styled.div`
    border-radius: 4px;
    
	@media (min-width: 900px) {
	    background-color: #fff;
	    padding: 1rem;
	    border-top: 4px solid ${primary};
	    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
	}
`;

const Summary = styled.div`
	display: none;
	
	@media (min-width: 900px) {
	    display: block;
	    text-align: center;
	    margin-bottom: 1rem;
	    font-weight: bold;
	}
`;

const Divider = styled.hr`
	display: none;
	
	@media (min-width: 900px) {
	    display: block;
	    border: 0;
	    border-bottom: 1px solid ${greyLight};
	    margin-bottom: 1rem;
	}
`;

const OrderInfo = styled.div`
	margin-bottom: 1rem;
`;

const LineItem = styled.div`
	display: flex;
`;

const Description = styled.div`
	font-weight: bold;
	margin-bottom: 0.5rem;
`;

const Value = styled.div`
	margin-left: auto;
`;

const CheckoutButton = styled(Button)`
	width: 100%;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    font-size: ${modularScale(0)};
    background: ${gradient};
    color: #fff;
    
    &:hover, &:focus, &:active {
	    color: #fff;
	}
	
	@media (min-width: 900px) {
	    margin-bottom: 0;
	}
`;

const Cart: React.FC = () => {
    const { cartItems, removeProduct } = useContext(CartContext);

    const onRemove = (id: string) => {
        removeProduct(id);
    }

    const formatPrice = (price) => {
        if (price === 0) {
            return `$ 0.00`;
        }

        const p = price.toString();

        const dollars = p.substr(0, p.length - 2);
        const cents = p.substr(p.length - 2, 2);

        return `$ ${dollars}.${cents}`;
    }

    const getSubtotal = () => {
        return getTotal();
    }

    const getTax = () => {
        return 0;
    }

    const getTotal = () => {
        return cartItems.reduce((total, item) => total + item.unitPrice, 0);
    }

    const renderEmptyCart = () => {
        if (cartItems.length < 1) {
            return (
                <>
                    <CartEmptyContainer>
                        Looks like you haven't added any items to your cart yet.
                        Browse the Pauliano collection to get started!
                    </CartEmptyContainer>
                    <ButtonContainer>
                        <StyledButton component={Link} to={'/sheets'} variant="contained" color="primary">
                            Browse collection
                        </StyledButton>
                    </ButtonContainer>
                </>
            )
        }
    }

    const renderCartItems = () => {
        if (cartItems.length < 1) return null

        return cartItems.map((item, i) => (
            <ItemContainer key={i}>
                <Image />
                <Info>
                    <ItemTitle>{item.title} - {item.subtitle}</ItemTitle>
                    <ItemPrice>{formatPrice(item.unitPrice)}</ItemPrice>
                </Info>
                <Remove onClick={() => onRemove(item.productId)} size="small"><Icon path={mdiClose}/></Remove>
            </ItemContainer>
        ))
    }

    const renderCheckoutDetails = () => {
        if (cartItems.length < 1) return null

        return (
            <OrderActions>
                <Summary>Order Details</Summary>
                <Divider />
                <OrderInfo>
                    <LineItem>
                        <Description>Subtotal</Description>
                        <Value>{formatPrice(getSubtotal())}</Value>
                    </LineItem>
                    <LineItem>
                        <Description>Tax</Description>
                        <Value>{formatPrice(getTax())}</Value>
                    </LineItem>
                    <Divider />
                    <LineItem>
                        <Description>Total</Description>
                        <Value><strong>{formatPrice(getTotal())}</strong></Value>
                    </LineItem>
                </OrderInfo>
                <CheckoutButton to='/checkout' component={Link} variant="contained">Checkout</CheckoutButton>
            </OrderActions>
        )
    }

    return (
        <Container>
            <Title>Cart</Title>
            {renderEmptyCart()}
            <Columns>
                <BigColumn>
                    <ItemList>
                        {renderCartItems()}
                    </ItemList>
                </BigColumn>
                <SmallColumn>
                    {renderCheckoutDetails()}
                </SmallColumn>
            </Columns>
        </Container>
    );
};

export default Cart;
