import React from 'react';

const SheetMusic: React.FC = ({ ...props }) => {
    return (
        <svg {...props} viewBox="0 0 512 512" width="512" fill="currentColor">
            <g>
                <path d="m449.439 52.14h-42.136v-42.14c0-5.523-4.477-10-10-10h-334.742c-5.523 0-10 4.477-10 10v304.25c0 5.523 4.477 10 10 10s10-4.477 10-10v-294.25h314.742v32.135h-272.607c-5.523 0-10 4.477-10 10v377.73h-32.135v-37.644c0-5.523-4.477-10-10-10s-10 4.477-10 10v47.644c0 5.523 4.477 10 10 10h42.14v42.135c0 5.523 4.477 10 10 10h334.738c5.523 0 10-4.477 10-10v-276.329c0-5.523-4.477-10-10-10s-10 4.477-10 10v266.329h-314.738v-419.86h314.738v65.602c0 5.523 4.477 10 10 10s10-4.477 10-10v-75.602c0-5.523-4.477-10-10-10z"/>
                <path d="m322.26 205.436c-19.52 0-35.4 15.88-35.4 35.399s15.88 35.4 35.4 35.4 35.399-15.88 35.399-35.4v-114.984c0-5.523-4.477-10-10-10h-97.728c-5.523 0-10 4.477-10 10v83.115c-4.659-2.26-9.883-3.53-15.399-3.53-19.52 0-35.399 15.88-35.399 35.399s15.88 35.4 35.399 35.4 35.399-15.88 35.399-35.4v-59.555h77.728v27.686c-4.658-2.26-9.883-3.53-15.399-3.53zm0 50.799c-8.492 0-15.4-6.908-15.4-15.4 0-8.491 6.908-15.399 15.4-15.399 8.491 0 15.399 6.908 15.399 15.399 0 8.492-6.908 15.4-15.399 15.4zm-97.728 0c-8.491 0-15.399-6.908-15.399-15.4 0-8.491 6.908-15.399 15.399-15.399s15.399 6.908 15.399 15.399c.001 8.492-6.908 15.4-15.399 15.4zm35.4-94.955v-25.429h77.728v25.429z"/>
                <path d="m384.017 314.917h-199.581c-5.523 0-10 4.477-10 10s4.477 10 10 10h199.581c5.523 0 10-4.477 10-10s-4.477-10-10-10z"/>
                <path d="m394.017 383.599c0-5.523-4.477-10-10-10h-199.581c-5.523 0-10 4.477-10 10s4.477 10 10 10h199.581c5.523 0 10-4.477 10-10z"/>
                <path d="m184.436 432.281c-5.523 0-10 4.477-10 10s4.477 10 10 10h144.368c5.523 0 10-4.477 10-10s-4.477-10-10-10z"/>
                <path d="m458.67 176.39c-2.067-5.042-8.036-7.49-13.06-5.41-5.04 2.087-7.485 8.028-5.4 13.06 2.087 5.038 8.016 7.476 13.05 5.4 5.029-2.075 7.505-8.022 5.41-13.05z"/>
                <path d="m53.33 361.7c3.354 8.187 15.369 7.995 18.549-.228 3.355-8.676-6.484-16.946-14.457-12.173-4.191 2.51-5.97 7.893-4.092 12.401z"/>
            </g>
        </svg>
    );
};

export default SheetMusic;
