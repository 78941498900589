import React from 'react';
import Layout from '../components/layouts/Layout';
import CartComponent from '../components/Cart';
import SEO from '../components/seo/SEO';

const Cart: React.FC = () => {
    return (
        <Layout customSEO>
            <SEO title={'Cart'} />
            <CartComponent />
        </Layout>
    );
};

export default Cart;
